<template>
  <div class="w-full cursor-pointer" @click="open = true">
    <label for="search" class="sr-only">Search</label>
    <div class="relative">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
      </div>
      <input
        v-model="query"
        id="search" name="search"
        class="block w-full rounded border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
        placeholder="Search" type="search"
      />
    </div>
  </div>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
          <DialogPanel
            class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox>
              <div class="relative">
                <MagnifyingGlassIcon
                  class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"/>
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..." @change="query = $event.target.value"/>
              </div>

              <ComboboxOptions
                v-if="response.results.length > 0" static
                class="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3">
                <ComboboxOption
                  v-for="item in response.results" :key="item.id" :value="item" as="template"
                  v-slot="{ active }">
                  <li :class="['flex cursor-pointer select-none rounded-xl p-3', active && 'bg-gray-100']" @click="select(item)">
                    <div :class="['flex h-10 w-10 flex-none items-center justify-center rounded-lg', item.color]">
                      <div class="h-10 w-10 text-white">
                        <img
                          v-if="item.media"
                          class="rounded"
                          :src="item.media.path"
                          alt=""/>
                        <img v-else src="/default.jpg" class="rounded" alt="">
                      </div>
                    </div>
                    <div class="ml-4 flex-auto">
                      <p :class="['text-sm font-bold', active ? 'text-gray-900' : 'text-gray-700']">
                        {{ item.name }}
                      </p>
                      <p :class="['text-sm line-2', active ? 'text-gray-700' : 'text-gray-500']">
                        {{ item.desc }}
                      </p>
                    </div>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>

              <div v-if="query !== '' && response.results.length === 0" class="px-6 py-14 text-center text-sm sm:px-14">
                <ExclamationCircleIcon type="outline" name="exclamation-circle" class="mx-auto h-6 w-6 text-gray-400"/>
                <p class="mt-4 font-semibold text-gray-900">No results found</p>
                <p class="mt-2 text-gray-500">No components found for this search term. Please try again.</p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import {MagnifyingGlassIcon} from '@heroicons/vue/20/solid'
import {
  ExclamationCircleIcon,
} from '@heroicons/vue/24/outline'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {useAuthFetch} from "~/composables/useAuthFetch.js";
import type {Project, ResponseProject} from "~/interface";
import {useRouter} from "#app";

const open = ref(false)
const query = ref('')

const params = computed(() => ({
  search: query.value
}))

const {data: response} = await useAuthFetch<ResponseProject>('/cms/projects/', {
  params: params
})

const select = (item: Project) => {
  open.value = false
  if (useRoute().path != `/${item.id_string}`) {
    useRouter().push(`/${item.id_string}`)
  }
}
</script>
