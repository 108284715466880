<template>
  <main id="main" class="flex flex-col">
    <partial-header/>
    <div class="flex-1">
      <slot class="w-full"/>
    </div>
    <partial-footer/>
  </main>
</template>

<style>
@supports (-webkit-touch-callout: none) {
  #main {
    margin-bottom: 20px;
  }
}
</style>
